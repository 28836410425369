import { MakerContactResponse } from "@/web-client/api";
import { FC } from "react";
import IconChecked from "@/assets/imgs/svg/icon-checked-fill.svg";
import { theme } from "tailwind.config";

type Props = {
  contact: MakerContactResponse;
  close: VoidFunction;
};

const MakerContactModalCompleteContent: FC<Props> = ({ contact, close }) => {
  return (
    <div className="flex flex-col gap-24">
      <h1 className="font-bold laptop:text-3xl text-xl text-center">
        お問い合わせを受け付けました
      </h1>

      <div className="flex flex-col items-center gap-24">
        <IconChecked width={100} height={100} fill={theme.colors.valid} />

        <div className="flex flex-col items-center">
          <small>お問い合わせ番号</small>
          <div className="text-3xl">{contact.code}</div>
        </div>

        <p className="text-sm text-center">
          メーカー担当者からの返信を今しばらくお待ちください。
          <br />
          また、確認のメールをお送りしましたので、お問い合わせ内容をご確認ください。
        </p>

        <button
          className="bg-black w-full p-8 rounded-xs text-white"
          type="button"
          onClick={close}
        >
          閉じる
        </button>
      </div>
    </div>
  );
};
export default MakerContactModalCompleteContent;
