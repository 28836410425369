import { ReactSelectDefaultStyles } from "@/consts/ReactSelect";
import { FC, useMemo, ReactNode } from "react";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import ReactSelect, { MenuPlacement } from "react-select";

type Props = {
  options: { label: ReactNode; value: string }[];
  name: string;
  rules: RegisterOptions<FieldValues>;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
};

const SingleSelect: FC<Props> = ({
  options,
  name,
  rules,
  placeholder,
  menuPlacement = "auto",
}: Props) => {
  const { register, watch, setValue, clearErrors } = useFormContext();
  const watchedValue = watch(name);

  const selectedOption = useMemo(
    () => options.find((o) => o.value == watchedValue),
    [options, watchedValue],
  );

  return (
    <>
      <ReactSelect
        isSearchable={false}
        options={options}
        value={selectedOption || { label: undefined, value: undefined }}
        onChange={(option) => {
          setValue(name, option?.value);

          // 値がセットされたらエラーを消す
          if (option?.value) {
            clearErrors(name);
          }
        }}
        styles={{ ...ReactSelectDefaultStyles() }}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
      />
      <input type="hidden" {...register(name, rules)} />
    </>
  );
};

export default SingleSelect;
